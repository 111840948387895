import { PersonalDetailModel } from '../../../models/PersonalDetailModel'
import PersonalDetailItem from './PersonalDetailItem';

const PersonalDetailList = (props: { model?:Array<PersonalDetailModel> }) => {
    if (props.model === null) {
        return null;
    }

    return (
        <div>
            {props.model?.map((personalDetailModel) => {
                return (
                    <PersonalDetailItem type={personalDetailModel.type} text={personalDetailModel.text} />
                );
            })}
        </div>
    );
}

export default PersonalDetailList;