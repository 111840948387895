const UserAddress = (props: { text: string, classes?: string; display: boolean }) => {

    if (!props.display) {
        return null;
    }

    return (
        <div><i className={`uil uil-location-point mr-2 ${props.classes}`}></i> <b> Address </b> : {props.text}</div>
    );
}

export default UserAddress;