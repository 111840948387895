import styles from './DeveloperCV.module.css'
import AchievementsItem from './AchievementsItem';
import TimeLine from './TimeLine';
import { TimeLineModel } from '../../../models/TimeLineModel';
import { TimelineType } from '../../../enums/TimelineType';
import ProfilePicture from '../../../components/ProfilePicture';
import UserPhone from '../../../components/UserPhone';
import UserEmail from '../../../components/UserEmail';
import SocialNetworkLink from '../../../components/SocialNetworkLink';
import { SocialNetworkTypes } from '../../../enums/SocialNetworkTypes';
import PersonalDetailList from './PersonalDetailsList';
import UserAddress from '../../../components/UserAddress';
import { useState, useEffect } from 'react';
import { CvDataModel } from '../../../models/CvDataModel';
import PersonalSkillList from './PersonalSkillList';
import PersonalTraitList from './PersonalTraitList';
import { WorkExperienceModel } from '../../../models/WorkexperienceModel';
import { API_URL } from '../../../config';

const DeveloperCV = (props: { userId: number }) => {

    const [cvData, setCvData] = useState<CvDataModel>();
    const [error, setError] = useState(null);

    var element=<div></div>;

    useEffect(() => {
        fetch(`${API_URL}Cv/GetCvInfo?id=${props.userId.toString()}`)
            .then((response) => response.json())
            .then((response) => {
                setCvData(response);
                setError(null);
            })
            .catch(setError);
    }, []);

    const workExperience: TimeLineModel = { timelineItems: cvData?.workExperienceList.map(s => ({text: s.companyName, small_title: s.jobPosition, dates: s.workPeriod})) };
    const education: TimeLineModel = { timelineItems: cvData?.educationList.map(s => ({text: s.institution, small_title: s.degree, dates: s.studyPeriod})) };
    
    return (
        <div className={styles.developer_main_wrapper}>
            <section className="section bg-profile" id="profile_ripple">
                <div className="zoo-profile">
                    <div className="container">                   
                        <div className="row">
                            <div className={`${styles.align_self_center} col-sm-6 mb-3 mb-lg-0`}>
                                <div className="zoo-profile-main">
                                    <div className="zoo-profile-main-pic">
                                        <ProfilePicture imageSrc={cvData?.personalInfo.profileImageUrl ?? ""} imageClasses={styles.profile_main_pic_img} isRound={true} display={cvData?.cvConfiguration.displayProfilePic ?? false} />
                                    </div>
                                    <div className="zoo-profile_user-detail">
                                        <h5 className="zoo-user-name">{cvData?.personalInfo?.firstName} {cvData?.personalInfo?.lastName}</h5>   
                                        <p className={styles.cd_headline}>
                                            <span>
                                                <b>{cvData?.mainData.profileHeadLine}</b>
                                            </span>
                                            <div className={styles.cd_words_wrapper}></div>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={`col-sm-4 col-print-4 ${styles.ml_auto}`}>
                                <ul className="list-unstyled personal-detail">
                                    <li key="userPhone"><UserPhone text={cvData?.personalInfo?.phoneNumber ?? ''} display={cvData?.cvConfiguration.displayPhone ?? false} /></li>
                                    <li key="userEmail" className="mt-2"><UserEmail text={cvData?.personalInfo?.email ?? ''} display={cvData?.cvConfiguration.displayEmail ?? false}/></li>
                                    <li key="userAddress" className="mt-2"><UserAddress text={cvData?.personalInfo?.address ?? ''} display={cvData?.cvConfiguration.displayAddress ?? false}/></li>
                                </ul>
                                <ul className="list-inline social-icon mb-0"> 
                                    <li key="userFacebook" className="list-inline-item">
                                        <SocialNetworkLink url={cvData?.personalInfo?.facebookUrl ?? ''} type={SocialNetworkTypes.Facebook} display={cvData?.cvConfiguration.displayFacebook ?? false} />
                                    </li>   
                                    <li key="userTwitter" className="list-inline-item">
                                        <SocialNetworkLink url={cvData?.personalInfo?.twitterUrl ?? ''} type={SocialNetworkTypes.Twitter} display={cvData?.cvConfiguration.displayTwitter ?? false} />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-md">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h4 className="header-title mb-3">About me !</h4>
                            <div className="text-underline"></div>
                        </div>
                        <div className="col-sm-6 col-print-6">
                            <h4 className="text-primary font-weight-bold">{cvData?.mainData.aboutMeHeader}</h4>
                            <p>
                                {cvData?.mainData.aboutMeBody}
                            </p>
                        </div>
                        <div className="col-sm-5 col-print-5 offset-lg-1 align-self-center">
                            <PersonalDetailList model={cvData?.personalDetailList} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-md">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h4 className="header-title mb-3">Education &amp; Skills</h4>
                            <div className="text-underline"></div>
                        </div>
                        <div className="col-sm-6 col-print-6">
                            <div className="row">
                                <div className="col-sm-6 col-print-6">
                                    <TimeLine type={TimelineType.Education} model={education}  />
                                </div>
                                <div className="col-sm-6 col-print-6">
                                    <TimeLine type={TimelineType.Experience} model={workExperience}  />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-print-6 align-self-center">
                            <div className="skills mt-4">
                                <PersonalSkillList model={cvData?.personalSkillList} />
                            </div>
                        </div>                                             
                    </div>
                </div>
            </section>

            <section className="bg-funfact section-md">
                <div className="container"> 
                    <PersonalTraitList model={cvData?.personalTraitList} />
                </div>
            </section>

            <section className="section-md">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h4 className="header-title mb-3">Responsibilities & Achievements</h4>
                            <div className="text-underline"></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="achievement-items">
                                { cvData?.workExperienceList.map((item: WorkExperienceModel) => {
                                    return (
                                        <AchievementsItem company={item.companyName} responsibilities={item.responsibilities} achievements={item.achievements} />
                                    );
                                }) }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}

export default DeveloperCV;