import { TimelineType } from '../../../enums/TimelineType';
import { TimeLineModel } from '../../../models/TimeLineModel';
import TimeLineItem from './TimelineItem';

const TimeLine = (props: { type: TimelineType; model:TimeLineModel} ) => {
    
    var componentTitle = "";
    var componentIcon = "";

    if(props.type === TimelineType.Education){
        componentTitle = "Education";
        componentIcon = "uil-graduation-hat";
    }

    if(props.type === TimelineType.Experience){
        componentTitle = "Experience";
        componentIcon = "uil-suitcase-alt";
    }
    
    return (
        <div>
            <div className="resume-icon">
                <i className={`uil ${componentIcon}`}></i>
                <h5 className="mt-n2">{componentTitle}</h5>
            </div>
            <div className="timeline">
            {props.model.timelineItems?.map((timeLineItem) => {
                return (
                    <TimeLineItem model={timeLineItem} />
                );
            })}
            </div>
    </div>
    );
}

export default TimeLine;