
const AchievementsItem = (props: { company: string, responsibilities: Array<string>, achievements: Array<string> }) => {

    return (
        <div className="item-info">
            <h5 className="my-1 text-dark">{props.company}</h5>
            <div className="row">
                <div className="col-sm-6 col-print-6">
                    <div>
                        <span className="personal-detail-title">Responsibilities</span>
                        <div className="personal-detail-info">
                            <ul>
                                {props.responsibilities.map((text: string) => {
                                    return (
                                        <li className="no-bullets">{text}</li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-print-6">
                    <div>
                        <span className="personal-detail-title">Achievements</span>
                        <div className="personal-detail-info">
                            <ul>
                                {props.achievements.map((text: string) => {
                                    return (
                                        <li className="no-bullets">{text}</li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AchievementsItem;


