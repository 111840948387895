const UserPhone = (props: { text: string, classes?: string; display: boolean }) => {

    if (!props.display) {
        return null;
    }

    return (
        <div><i className={`uil uil-phone-volume mr-2 ${props.classes}`}></i> <b> Phone </b> : {props.text}</div>
    );
}

export default UserPhone;