import { PersonalDetailType } from "../../../enums/PersonalDetailType";

const PersonalDetailItem = (props: { type: PersonalDetailType, text: string }) => {

    var title = "";

    if(props.type === PersonalDetailType.Name){
        title = "Name";
    }
    if(props.type === PersonalDetailType.DateOfBith){
        title = "Date of birth";
    }
    if(props.type === PersonalDetailType.SpokenLanguages){
        title = "Spoken Languages";
    }
    if(props.type === PersonalDetailType.Nationality){
        title = "Nationality";
    }
    if(props.type === PersonalDetailType.Interest){
        title = "Interest";
    }
    if(props.type === PersonalDetailType.CurrentLocation){
        title = "Current Location";
    }

    return (
        <p>
            <span className="personal-detail-title">{title}</span>
            <span className="personal-detail-info">{props.text}</span>
        </p>
    );
}

export default PersonalDetailItem;