import { PersonalTraitModel } from '../../../models/PersonalTraitModel'
import PersonalTrait from './PersonalTrait';

const PersonalTraitList = (props: { model?:Array<PersonalTraitModel> }) => {
    if (props.model === null) {
        return null;
    }

    return (
        <div className="row justify-content-center">
            {props.model?.map((personalTraitModel) => {
                return (
                    <div className="col-sm-4 digit-counter">
                        <PersonalTrait type={personalTraitModel.type} text={personalTraitModel.text} additional_text={personalTraitModel.additionalText} />
                    </div>
                );
            })}
        </div>
    );
}

export default PersonalTraitList;