import { PersonalSkillModel } from '../../../models/PersonalSkillModel';
import SkillMeasure from './SkillMeasure';

const PersonalSkillList = (props: { model?:Array<PersonalSkillModel> }) => {
    if (props.model === null) {
        return null;
    }

    return (
        <div>
            {props.model?.map((skillItem: PersonalSkillModel) => {
                return (
                    <SkillMeasure skill_name={skillItem.skillName} skill_percentage={skillItem.skillPercentage}/>
                );
            })            
            }
        </div>
    );
}

export default PersonalSkillList;