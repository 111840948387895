import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from './containers/common/Home';
import NotFound from "./containers/common/NotFound";

function App() {

  return (
    <div className="App">
      <div className="content">
        <Router>
          <div>
            <Routes>
              <Route path="/" element={<NotFound />} />
              <Route path="/:userid" element={<Home />} />
            </Routes>
          </div>
        </Router>
      </div>
    </div>
  );
}

export default App;
