import { TimeLineItemModel } from '../../../models/TimeLineItemModel';

const TimeLineItem = (props: { model: TimeLineItemModel }) => {
    return (
        <div className={`time-item ${props.model.styles}`}>
            <div className="item-info">
                <div className="font-14">{props.model.dates}</div>
                <h5 className="my-1 text-dark">{props.model.small_title}</h5>
                <p>{props.model.text}</p>
            </div>
        </div>
    );
}

export default TimeLineItem;