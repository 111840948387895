import { useParams } from "react-router-dom";
import DeveloperCV from "../cvs/DeveloperCV1/DeveloperCV";
import { useState, useEffect } from 'react';
import NotFound from "./NotFound";
import { UserInfoModel } from "../../models/UserInfoModel";
import { API_URL } from '../../config';

const Home = (props: any) => {
    const {userid} = useParams();
    const [userInfo, setUserInfo] = useState<UserInfoModel>();
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch(`${API_URL}User/GetUserInfo?username=${userid}`)
            .then((response) => response.json())
            .then((response) => {
                setUserInfo(response);
                setError(null);
            })
            .catch(setError);
    }, []);

    const renderCv = (template: number = 0, id: number = 0) =>  {
        switch(template) {
            case 1000:
            return <DeveloperCV userId={id} />;
        case 1001:
            return <div>Second CV Template</div>
        default:
            //change this to loading
            return <NotFound />;
        }
    }

    return (
        <div>
            { renderCv(userInfo?.cvTemplate, userInfo?.id) }
        </div>
    );
}

export default Home;