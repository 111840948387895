const ProfilePicture = (props: { imageSrc: string, imageClasses: string; isRound: boolean; display: boolean }) => {

    if (!props.display) {
        return null;
    }

    var classes = props.imageClasses;
    
    if(props.isRound){
        classes += " rounded-circle";
    }

    return (
        <div>
            <img src={props.imageSrc} alt="" className={classes} />
        </div>
    );
}

export default ProfilePicture;