import { PersonalTraitType } from "../../../enums/PersonalTraitType";

const PersonalTrait = (props: { type: PersonalTraitType; text: string; additional_text: string; }) => {
    var iconColor = "";
    var icon = "";
    var traitText = "";

    if (props.type === PersonalTraitType.CompletedProjects){
        iconColor = "text-warning";
        icon = "uil-cell";
        traitText = "Completed Projects";
    }
    if (props.type === PersonalTraitType.BooksPerYear){
        iconColor = "text-success";
        icon = "uil-book-reader";
        traitText = "Books read per year";
    }
    if (props.type === PersonalTraitType.AwardsReceived){
        iconColor = "text-pink";
        icon = "uil-award";
        traitText = "Awards Received";
    }
    
    return (
        <div className="media">
            <i className={`uil ${icon} ${iconColor} mr-2 align-self-center`}></i>
            <div className="media-body align-self-center">
                <h3 className="mb-1"><span className="counter-value" data-count="1500">{props.text}</span>{props.additional_text}</h3>  
                <h5 className="counter-name mt-0">{traitText}</h5>
            </div>
        </div>
    );
}

export default PersonalTrait;