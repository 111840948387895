import { SocialNetworkTypes } from "../enums/SocialNetworkTypes";

const SocialNetworkLink = (props: { url: string, type: SocialNetworkTypes, display: boolean }) => {

    if (!props.display) {
        return null;
    }

    var element=<div></div>;
    if(props.url != null) {
        if(props.type === SocialNetworkTypes.Facebook){
            element=<a href={props.url} className="bg-blue"> <i className="uil uil-facebook-f"></i> </a>;
        }
        if(props.type === SocialNetworkTypes.Twitter){
            element=<a href={props.url} className="bg-secondary"> <i className="uil uil-twitter"></i> </a>;
        }
    }
    return(
      <div>
       {element}
      </div>
    );
}

export default SocialNetworkLink;