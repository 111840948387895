const SkillMeasure = (props: { skill_name: any; skill_percentage: any; }) => {
    return (
        <div className="skill-box"> 
            <h4 className="skill-title">{props.skill_name}</h4> 
            <div className="progress-line"> 
                <span data-percent={props.skill_percentage} style={{width: `${props.skill_percentage}%`}}>
                    <span className="percent-tooltip">{props.skill_percentage}%</span>
                </span> 
            </div>
        </div>
    );
}

export default SkillMeasure;